import "materialize-css/dist/js/materialize.min"
import {GetPredefs, Predefs} from "./lib/predefs"
import {IsPageWithLocale} from "./lib/urlutils";
import {ValidateEmail, ValidateLength} from "./lib/validate";

$(document).ready(function() {
    if (IsPageWithLocale("/contact")) {
        GetPredefs();

        $('#sendContactMessageButton').click(function () {
            sanitizeContactForm();
            if(!validateContactForm()) {
                return;
            }

            let params = {
                email: $('#contactFormEmail').val(),
                message_content: $('#contactFormMessage').val(),
                language: $('#languageContactForm').val()
            }
            $('#sendingInProgressModal').modal('open');

            $.ajax(Predefs.links.backend_url + "/contact", {
                method: "POST",
                data: JSON.stringify(params),
                success: function () {
                    $('#sendingInProgressModal').modal('close');
                    $('#sendingMessageSuccessfullyModal').modal('open');
                    console.log("success");
                },
                error: function () {
                    $('#sendingInProgressModal').modal('close');
                    console.log("error");
                }
            });
        });

        $('#contactFormEmail').focusout(function () {
            sanitizeContactForm();
            validateEmailField();
        });
        $('#contactFormMessage').focusout(function () {
            sanitizeContactForm();
            validateContentField();
        });
    }
});

function sanitizeContactForm() {
    let contactFormEmail = $('#contactFormEmail');
    let contactFormMessage = $('#contactFormMessage');

    $(contactFormEmail).val($(contactFormEmail).val().trim());
    $(contactFormMessage).val($(contactFormMessage).val().trim());
}

function validateContactForm() {
    let emailValidate = validateEmailField();
    let contentValidate = validateContentField();
    return emailValidate && contentValidate
}

function validateEmailField() {
    let contactFormEmail = $('#contactFormEmail');

    if(!ValidateEmail($(contactFormEmail).val())) {
        $(contactFormEmail).removeClass('valid');
        $(contactFormEmail).addClass('invalid');
        return  false;
    }

    $(contactFormEmail).addClass('valid');
    $(contactFormEmail).removeClass('invalid');
    return true
}

function validateContentField() {
    let contactFormMessage = $('#contactFormMessage');

    if(!ValidateLength($(contactFormMessage).val())) {
        $(contactFormMessage).removeClass('valid');
        $(contactFormMessage).addClass('invalid');
        return false;
    }

    $(contactFormMessage).addClass('valid');
    $(contactFormMessage).removeClass('invalid');
    return true;
}