import {LoginCookieKey} from "../lib/constant";
import {Predefs, GetPredefs} from "../lib/predefs";

$(document).ready(function() {
    GetPredefs();
    $('#sendAttendeeResponseBtn').click(function() {
        let progressModal = $('#responseProgressModal');
        $(progressModal).modal('open');

        let calendarID = $('#calendarIdField').val();
        let eventID = $('#eventIdField').val();

        let params = {
            attendee_id: parseInt($('#attendeeIdField').val()),
            security_token: $('#securityTokenField').val(),
            declined_reason: $('#declinedReasonArea').val(),
        };

        $.ajax(Predefs.links.backend_url + "/calendar/" + calendarID + "/events/" + eventID + "/reason", {
            method: "POST",
            data: JSON.stringify(params),
            success: function (data) {
                $(progressModal).modal('close');
                $('#successModal').modal('open');
            },
            error: function () {
                $(progressModal).modal('close');
                $('#errorMessageModal').modal('open');
            }
        })
    })
});
