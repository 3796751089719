import {GetPredefs, Predefs} from "../lib/predefs"

$(document).ready(function() {
    if (window.location.pathname.startsWith("/user/reset-password/") && window.location.pathname.length === 57) {
        GetPredefs();

        $('#resetPasswordButton').click(function (e) {
            e.preventDefault();
            resetPassword();
        });
    }
});

function resetPassword() {
    console.log("TO TAM");
    let newPasswordInput = $('#passwordInput')

    if($(newPasswordInput).val() !== $('#retypePasswordInput').val()) {
        $('#retypePasswordErrorModal').modal('open');
        return
    }

    let token = window.location.pathname.split("/")[3];

    let params = {
        token: token,
        new_password: $(newPasswordInput).val()
    }

    $(progressModalHeader).html("Resetting password");
    $('#progressModal').modal('open');
    $.ajax(Predefs.links.backend_url + '/user/reset_password/reset', {
        method: 'POST',
        data: JSON.stringify(params),
        success: function () {
            $('#progressModal').modal('close');
            $('#resetPasswordSuccessModal').modal('open');
        },
        error: function () {
            $('#progressModal').modal('close');
            $('#resetPasswordErrorModal').modal('open');
        }
    })
}

function getPredefs2() {
    $.ajax(window.location.protocol + "//" + window.location.host + '/predefs', {
        method: 'GET',
        success: function (data) {
            Predefs = data;
        }
    })
}
