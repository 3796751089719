import {GetPredefs, Predefs, T} from "../lib/predefs"
import {IsPageWithLocale} from "../lib/urlutils";
import {LoginCookieKey} from "../lib/constant"

$(document).ready(function() {
    if (!IsPageWithLocale("/user/login")) {
        return
    }

    GetPredefs();

    const userLoginForm = $("#userLoginForm");
    let loginInProgressModal = $("#loginInProgressModal");
    let loginErrorModal = $("#loginErrorModal");

    $(userLoginForm).submit(function (evt) {
        evt.preventDefault();
        $(loginInProgressModal).modal("open");

        const loginRequest = {
            email: $("#email").val(),
            password: $("#password").val()
        };

        $.ajax(Predefs.links.backend_url + "/user/login", {
            data: JSON.stringify(loginRequest),
            method: "POST",
            success: function (data) {
                const response = JSON.parse(data);
                $(loginInProgressModal).modal("close");

                if (response.hasGoogle2FA || response.hasEmail2FA) {
                    window.location.href = "/user/2fa?token=" + response.token;
                    return
                }

                Cookies.set(LoginCookieKey, response.token, {expires: 1});
                Cookies.set('locale', response.language)
                window.location.href = "/calendar";
            },
            error: function (xhr, testStatus, errorThrown) {
                $(loginInProgressModal).modal("close");

                if(xhr.status >= 400) {
                    let responseObj = JSON.parse(xhr.responseText);
                    console.log(responseObj.code);
                    switch (responseObj.code) {
                        case 3000:
                            $(loginErrorModal).find("#loginErrorText").html(T("login_page.password_incorrect"));
                            break;
                        case 3003:
                            $(loginErrorModal).find("#loginErrorText").html(T("login_page.email_incorrect"));
                            break;
                        case 3014:
                            $(loginErrorModal).find("#loginErrorText").html(T("login_page.rate_limit_reached"));
                            break;
                        default:
                            $(loginErrorModal).find("#loginErrorText").html(responseObj.message);
                            break;
                    }
                } else {
                    $(loginErrorModal).find("#loginErrorText").html(T("login_page.unknown_error"));
                }

                $(loginErrorModal).modal("open");
            }
        })
    });

    $('.toggle-visibility').click(function (e) {
        e.preventDefault();
        let input = $(this).siblings('input');
        let type = $(input).prop('type');
        let icon = $(this).find('span');

        if(type === 'password') {
            $(input).prop('type', 'text');
            $(icon).html('visibility_off');
        } else {
            $(input).prop('type', 'password');
            $(icon).html('visibility');
        }
    });
})
