import {GetPredefs, Predefs, T} from "../lib/predefs"
import {IsPageWithLocale} from "../lib/urlutils";
import {LoginCookieKey} from "../lib/constant";

$(document).ready(function() {
    if (IsPageWithLocale("/user/2fa")) {
        GetPredefs()
        Set2FAFormByHash();

        $(window).on('hashchange', function(){
            Set2FAFormByHash();
        });

        $('#google2FALoginButton').click(function (){
            UserLogin2FA($('#googleVerificationCodeInput').val(), "GOOGLE");
        });

        $('#email2FALoginButton').click(function (){
           UserLogin2FA($('#emailVerificationCodeInput').val(), "EMAIL");
        });
    }
});

function Set2FAFormByHash() {
    if(window.location.hash === '#google') {
        $('#google2FAForm').removeClass('hide');
        $('#email2FAForm').addClass('hide');
        $('#listOf2FA').addClass('hide');
    } else if(window.location.hash === '#email') {
        $('#google2FAForm').addClass('hide');
        $('#email2FAForm').removeClass('hide');
        $('#listOf2FA').addClass('hide');

        Email2FARequest();
    } else {
        $('#google2FAForm').addClass('hide');
        $('#email2FAForm').addClass('hide');
        $('#listOf2FA').removeClass('hide');
    }
}

function UserLogin2FA(verificationCode, otpType) {
    const queryParams = new URLSearchParams(window.location.search);
    const params = {
        "otp_type": otpType,
        "verification_code": verificationCode
    }

    let loginInProgressModel = $('#loginInProgressModal');
    $(loginInProgressModel).modal('open');
    $.ajax(Predefs.links.backend_url + "/user/login/2fa", {
        method: "POST",
        headers: {
            "X-User-Token": queryParams.get("token"),
        },
        data: JSON.stringify(params),
        success: function (data) {
            $('#loginInProgressModal').modal('close');
            const response = JSON.parse(data);
            Cookies.set(LoginCookieKey, response.token, {expires: 1});
            Cookies.set('locale', response.language)
            window.location.href = "/calendar";
        },
        error: function (xhr) {
            $(loginInProgressModel).modal('close');

            let loginErrorModal = $('#loginErrorModal');
            if(xhr.status >= 400) {
                let responseObj = JSON.parse(xhr.responseText);
                switch (responseObj.code) {
                    case 9004:
                        $(loginErrorModal).find("#loginErrorText").html(T("login_page.verification_code_is_incorrect"));
                        break;
                    default:
                        $(loginErrorModal).find("#loginErrorText").html(responseObj.message);
                        break;
                }
            } else {
                $(loginErrorModal).find("#loginErrorText").html(T("login_page.unknown_error"));
            }

            $(loginErrorModal).modal('open');
        }
    });
}

function Email2FARequest() {
    const queryParams = new URLSearchParams(window.location.search);

    $.ajax(Predefs.links.backend_url + "/otp/email/send_code", {
        method: "GET",
        headers: {
            "X-User-Token": queryParams.get("token"),
        },
        success: function (data) {
            console.log("success");
        },
        error: function () {
            console.log("error");
        }
    });
}
