import "../stylesheets/homePage.scss"

import "jquery/dist/jquery.min"
import "materialize-css/dist/js/materialize.min"

import "./contact"
import "./forms/userLoginForm"
import "./forms/userRegisterForm"
import "./forms/user2FAForm"
import "./forms/userResetPasswordStep1"
import "./forms/userResetPasswordStep2"
import "./forms/attendeeDeclinedReasonForm"

import {IsPageWithLocale} from "./lib/urlutils";

$(document).ready(function() {
    materializeInit();
    cookiePolicyInit();

    if (IsPageWithLocale("")) {
        $('#mainPageRegisterNowButton').click(function (e) {
            e.preventDefault();

            /*let queryParams = new URLSearchParams()
            queryParams.set('firstName', $('#registerFirstName').val());
            queryParams.set('lastName',  $('#registerLastName').val());
            queryParams.set('email',     $('#registerEmail').val());

            window.location.href = $('#homepageRegisterForm').attr('action') + '?' + queryParams.toString()*/

            $('#homepageRegisterForm').submit();
        });
    }
});

function materializeInit() {
    $('.dropdown-trigger').dropdown();
    $('.modal').modal();
    $('.sidenav').sidenav();
    $('select').formSelect();
}

function cookiePolicyInit() {
    if (!Cookies.get('isAccepted')) {
        setTimeout(function () {
            $('#cookieAcceptButton').click(function () {
                var date = new Date();
                date.setTime(date.getTime() + (365*24*60*60*1000));

                Cookies.set('isAccepted', true, {
                    expires: date
                });
                $('#cookieModal').modal('close');
            });

            $('#cookieModal').modal('open');
        }, 1000);
    }
}
