import {GetPredefs, Predefs} from "../lib/predefs"
import {IsPageWithLocale} from "../lib/urlutils";

$(document).ready(function() {
    if (IsPageWithLocale("/user/reset-password")) {
        GetPredefs();

        $('#resetPasswordButton').click(function (e) {
            e.preventDefault();

            resetPassword();
        })
    }
});

function resetPassword() {
    let params = {
        email: $('#emailInput').val(),
        language: $('#resetPasswordLanguage').val()
    }

    $('#progressModal').modal('open');

    $.ajax(Predefs.links.backend_url + "/user/reset_password/email", {
        method: "POST",
        data: JSON.stringify(params),
        success: function () {
            $('#progressModal').modal('close');
            $('#resetPasswordSendMailModal').modal('open')
        },
        error: function (xhr) {
            let responseObj = JSON.parse(xhr.responseText);
            console.log(responseObj);

            $('#progressModal').modal('close');
            $('#errorText').html(responseObj.message)
            $('#errorModal').modal('open')
        }
    });
}

function getPredefs2() {
    $.ajax(window.location.protocol + "//" + window.location.host + '/predefs', {
        method: 'GET',
        success: function (data) {
            predefs = data;
        }
    })
}