import {GetPredefs, Predefs, T} from "../lib/predefs"
import {IsPageWithLocale} from "../lib/urlutils";
import {ValidateEmail, ValidateLength} from "../lib/validate";

$(document).ready(function() {
    if (!IsPageWithLocale("/user/register")) {
        return
    }

    GetPredefs();

    const userLoginForm = $("#userRegisterForm");

    const passwordObj = $("#password");
    const retypePasswordObj = $("#retypePassword");
    const emailInput = $('#email')
    const firstNameInput = $('#firstName');
    const lastNameInput = $('#lastName');

    let registerInProgressModal = $("#registerInProgressModal");
    let confirmEmailModal = $("#confirmEmailInfoModal");
    let registerErrorModal = $("#registrationErrorModal");
    let resendConfirmationTokenModal = $("#resendConfirmationTokenModal");

    let registrationResendEmailButton = $("#registrationResendEmailButton");

    $(emailInput).focusout(function () {
        sanitizeRegisterForm();
        validateEmailInput();
    });

    $(passwordObj).focusout(function () {
        sanitizeRegisterForm();
        validatePassword();
    });

    $(retypePasswordObj).focusout(function () {
        sanitizeRegisterForm();
        validateRetypePassword();
    })

    $(firstNameInput).focusout(function () {
        sanitizeRegisterForm();
        validateFirstName();
    });

    $(lastNameInput).focusout(function () {
        sanitizeRegisterForm();
        validateLastName();
    });

    $(userLoginForm).submit(function (evt) {
        evt.preventDefault();

        sanitizeRegisterForm();
        if(!validateRegisterForm()) {
            $(registerErrorModal).modal("open");
            $(registerErrorModal).find("#registrationErrorText").html(
                T("register_page.register_form_has_errors")
            );
            return;
        }

        if(!$('#termsOfUseAgreement').is(':checked')) {
            $(registerErrorModal).modal("open");
            $(registerErrorModal).find("#registrationErrorText").html(
                T("register_page.terms_of_use_are_not_accept")
            );
           return;
        }

        if(!$('#privacyPolicyAgreement').is(':checked')) {
            $(registerErrorModal).modal("open");
            $(registerErrorModal).find("#registrationErrorText").html(
                T("register_page.privacy_policy_is_not_accept")
            );
            return;
        }

        $(registerInProgressModal).modal("open");
        const loginRequest = {
            email: $(emailInput).val(),
            password: $(passwordObj).val(),
            first_name: $(firstNameInput).val(),
            last_name: $(lastNameInput).val(),
            phone_number: $('#phoneNumber').val(),
            language: $('#language').val()
        };

        $.ajax(Predefs.links.backend_url + "/user/register", {
            data: JSON.stringify(loginRequest),
            method: "POST",
            success: function (data, textStatus, xhr) {
                $(registerInProgressModal).modal("close");
                $(confirmEmailModal).modal("open");

                let userIdObj = JSON.parse(data);
                $(registrationResendEmailButton).data("userId", userIdObj.user_id);
            },
            error: function (xhr, textStatus, err) {
                $(registerErrorModal).find("#registrationErrorText").html(T("register_page.unknown_error"));

                if(xhr.status >= 400) {
                    let response = JSON.parse(xhr.responseText);
                    console.log(response);
                    $(registerErrorModal).find("#registrationErrorText").html(response.message);
                }

                $(registerInProgressModal).modal("close");
                $(registerErrorModal).modal("open");
            }
        })
    });

    $(registrationResendEmailButton).click(function () {
        $(registerInProgressModal).modal("open");
        let userId = $(registrationResendEmailButton).data("userId");

        $.ajax(Predefs.links.backend_url + "/user/" + userId + "/resend_confirmation_token", {
            method: "GET",
            success: function (data, textStatus, xhr) {
                $(registerInProgressModal).modal("close");
                console.log({
                    data: data,
                    status: textStatus,
                    xhr: xhr,
                });
                $(resendConfirmationTokenModal).modal("open");
            },
            error: function (xhr, textStatus, err) {
                $(registerErrorModal).find("#registrationErrorText").html(T("register_page.unknown_error"));

                if(xhr.status >= 400) {
                    let response = JSON.parse(xhr.responseText);
                    $(registerErrorModal).find("#registrationErrorText").html(response.message);
                }

                $(registerInProgressModal).modal("close");
                $(registerErrorModal).modal("open");
            }
        })
    });

    $('.toggle-visibility').click(function (e) {
        e.preventDefault();
        let input = $(this).siblings('input');
        let type = $(input).prop('type');
        let icon = $(this).find('span');

        if(type === 'password') {
            $(input).prop('type', 'text');
            $(icon).html('visibility_off');
        } else {
            $(input).prop('type', 'password');
            $(icon).html('visibility');
        }
    })
})

function sanitizeRegisterForm() {
    const emailInput = $('#email')
    const firstNameInput = $('#firstName');
    const lastNameInput = $('#lastName');

    $(emailInput).val($(emailInput).val().trim());
    $(firstNameInput).val($(firstNameInput).val().trim());
    $(lastNameInput).val($(lastNameInput).val().trim());
}

function validateRegisterForm() {
    let emailValidate = validateEmailInput();
    let passwordValidate = validatePassword();
    let retypePasswordValidate =  validateRetypePassword();
    let firstNameValidate = validateFirstName();
    let lastNameValidate = validateLastName();
    return emailValidate && passwordValidate && retypePasswordValidate && firstNameValidate && lastNameValidate;
}

function validateEmailInput() {
    let emailInput = $('#email');

    if(!ValidateEmail($(emailInput).val())) {
        $(emailInput).removeClass('valid');
        $(emailInput).addClass('invalid');
        return  false;
    }

    $(emailInput).addClass('valid');
    $(emailInput).removeClass('invalid');
    return true
}

function validatePassword() {
    let passwordInput = $('#password');

    if(!ValidateLength($(passwordInput).val())) {
        $(passwordInput).removeClass('valid');
        $(passwordInput).addClass('invalid');
        return  false;
    }

    $(passwordInput).addClass('valid');
    $(passwordInput).removeClass('invalid');
    return true
}

function validateRetypePassword() {
    let retypePasswordInput = $('#retypePassword');
    let passwordInput = $('#password');

    if(!ValidateLength($(retypePasswordInput).val()) || $(retypePasswordInput).val() !== $(passwordInput).val()) {
        $(retypePasswordInput).removeClass('valid');
        $(retypePasswordInput).addClass('invalid');
        return false;
    }

    $(retypePasswordInput).addClass('valid');
    $(retypePasswordInput).removeClass('invalid');
    return true
}

function validateFirstName() {
    let firstName = $('#firstName');

    if(!ValidateLength($(firstName).val())) {
        $(firstName).removeClass('valid');
        $(firstName).addClass('invalid');
        return  false;
    }

    $(firstName).addClass('valid');
    $(firstName).removeClass('invalid');
    return true
}

function validateLastName() {
    let lastNameInput = $('#lastName');

    if(!ValidateLength($(lastNameInput).val())) {
        $(lastNameInput).removeClass('valid');
        $(lastNameInput).addClass('invalid');
        return  false;
    }

    $(lastNameInput).addClass('valid');
    $(lastNameInput).removeClass('invalid');
    return true
}
