import {LoginCookieKey} from "./constant"

export let Predefs;

export function GetPredefs() {
    $.ajax(window.location.protocol + "//" + window.location.host + '/predefs', {
        method: 'GET',
        headers: {
            "X-User-Token": Cookies.get(LoginCookieKey)
        },
        success: function (data) {
            Predefs = data;
        }
    })
}

export function T(key) {
    return Predefs.translations[key];
}